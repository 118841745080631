/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100% !important'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
      <div id="login-bg" style={{backgroundImage: `url(${toAbsoluteUrl('/revivee/revivee_bg.jpg')})`, height:"100vh !important"}}
           className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            <div id="login-form-wrapper" className='w-lg-500px p-10'>
              <div className='text-center mb-3'>
                <img style={{width:"350px"}} src={toAbsoluteUrl("/revivee/logo-full.png")} alt=""/>
              </div>
              <Outlet />
            </div>
          </div>
          {/* end::Form */}
        </div>
      </div>
  )
}

export {AuthLayout}
