const env = {
    PORT:3011,
    REACT_APP_API_URL:"https://api.revivee.com/api/admin",
    PUBLIC_URL:"/",
    IMAGES_URL:"https://api.revivee.com/public",
    API_KEY:"3154f2a10b4aecaa9ae8c10468cd8227",
}

export default env;

