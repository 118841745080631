import {lazy, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from "../../_metronic/assets/ts/_utils";
const PrivateRoutes = () => {
    const UsersPage = lazy(() => import('../modules/admin/users/UsersPage'))
    const ChargersPage = lazy(() => import('../modules/admin/chargers/ChargerPage'))
    const BookingPage = lazy(() => import('../modules/admin/bookings/BookingPage'))
    const ReviewPage = lazy(() => import('../modules/admin/reviews/ReviewPage'))
    const PaymentPage = lazy(() => import('../modules/admin/payments/PaymentPage'))
    const EarningPage = lazy(() => import('../modules/admin/earnings/EarningPage'))
    const DashboardWrapper = lazy(() => import("../modules/admin/dashboard/DashboardWrapper"))

    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path='auth/*' element={<Navigate to='/dashboard' />} />
                <Route path='/dashboard' element={
                    <SuspensedView>
                        <DashboardWrapper/>
                    </SuspensedView>
                    }
                />
                <Route
                    path='users/*'
                    element={
                        <SuspensedView>
                            <UsersPage />
                        </SuspensedView>
                    }
                />
                <Route path='users' element={
                    <SuspensedView>
                        <UsersPage />
                    </SuspensedView>
                } />
                <Route
                    path='chargers/*'
                    element={
                        <SuspensedView>
                            <ChargersPage />
                        </SuspensedView>
                    }
                />
                <Route path='chargers' element={
                    <SuspensedView>
                        <ChargersPage />
                    </SuspensedView>
                } />
                <Route
                    path='bookings/*'
                    element={
                        <SuspensedView>
                            <BookingPage />
                        </SuspensedView>
                    }
                />
                <Route path='bookings' element={
                    <SuspensedView>
                        <BookingPage />
                    </SuspensedView>
                } />
                <Route
                    path='reviews/*'
                    element={
                        <SuspensedView>
                            <ReviewPage />
                        </SuspensedView>
                    }
                />
                <Route path='reviews' element={
                    <SuspensedView>
                        <ReviewPage />
                    </SuspensedView>
                } />
                <Route
                    path='earnings/*'
                    element={
                        <SuspensedView>
                            <EarningPage />
                        </SuspensedView>
                    }
                />
                <Route path='earnings' element={
                    <SuspensedView>
                        <EarningPage />
                    </SuspensedView>
                } />
                <Route
                    path='payments/*'
                    element={
                        <SuspensedView>
                            <PaymentPage />
                        </SuspensedView>
                    }
                />
                <Route path='payments' element={
                    <SuspensedView>
                        <PaymentPage />
                    </SuspensedView>
                } />
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    )
}

const SuspensedView = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary')
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    })
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
