import env from "./constants";
import axios from 'axios';
export const axiosInstance = axios.create({
    baseURL: env.REACT_APP_API_URL,
    withCredentials: false,
});
const onRequest = (config) => {
    config.headers.apitoken = "3154f2a10b4aecaa9ae8c10468cd8227"
    return config;
}
axiosInstance.interceptors.request.use(onRequest, null);
export default axiosInstance;
